import React, { useEffect, useState } from 'react'
import SurveyAccordion from './accordions/SurveyAccordion'
import Gallery from './galleries/Gallery.js'
import { appConstants } from 'utils/constants.js'
import { Container, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'

export default function SurveyDetails(props) {
  const [report, setReport] = useState({})
  const [sections, setSections] = useState({})
  const [sectionOrder, setSectionOrder] = useState([])

  const loadReport = async() => {
    const res = await axios.get(`${appConstants.CONTEXT_REPORT_URL}${props.id}`)
    setReport(res.data["data"])
    setSections(res.data["data"]["sections"])
    setSectionOrder(res.data["data"]["section_order"])
  }

  useEffect(() => {
    loadReport()
  }, [props.id])

  return (
    <Container>
      { Object.keys(report).length > 0 ? (
      <div>
        <h5>{report.title}</h5>
        <p className="text-muted">{report.visit_date} | {parseFloat(report.total_score).toFixed(2)}</p>
      </div>) : "" }
      <Row>
        <Col md={12}>
          {sections && sectionOrder ? (<SurveyAccordion sections={sections} sectionOrder={sectionOrder} />) : ""}
        </Col>
        <Col md={12}>
          <hr/>
          <Gallery
            url={appConstants.PHOTOS_FOR_CONTEXT_URL + String(props.id)}
            title={I18n.t('activerecord.attributes.monitoring_request.site_visit_images')} />
        </Col>
      </Row>
    </Container>
  )
}
