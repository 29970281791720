import React, { useEffect, useState } from 'react'
import I18n from 'i18n-js/locales.js'
import axios from 'axios'
import ImageGallery from 'react-image-gallery'

export default function Gallery(props) {
  const [images, setImages] = useState([])
  
  const getImages = async() => {
    const res = await axios.get(props.url)

    setImages(res.data.map(i => {
      const g = {}
      g["original"] = i.url
      g["description"] = i.name
      return g
    }))
  }

  useEffect(() => {
    getImages()
  }, [props.url])
  
  return (
    <div>
      <h5>{props.title}</h5>
      {images.length > 0 ? <ImageGallery showThumbnails={false} items={images} /> : I18n.t('activerecord.attributes.monitoring_request.no_images')}
    </div>
  )
}
