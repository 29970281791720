import React, { useEffect, useState } from 'react'
import { appConstants } from 'utils/constants.js'
import { Container, Row, Col, Button, ListGroup, Card } from 'react-bootstrap'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import ImageGallery from 'react-image-gallery'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { BiChevronRight, BiChevronLeft } from "react-icons/bi"
import styled from 'styled-components'
import Iframe from '../details/Iframe'

const PDFCol = styled(Col)`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export default function CollectionDetails(props) {
  const [collection, setCollection] = useState({})
  const [collectionResources, setCollectionResources] = useState([])
  const [collectionPhotos, setCollectionPhotos] = useState([])
  const [tpmMaps, setTpmMaps] = useState([])
  const [tpmSummaryReport, setTpmSummaryReport] = useState("")
  const [virtualTour, setVirtualTour] = useState("")
  const [video, setVideo] = useState("")
  const [show, setShow] = useState(props.show)

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const loadCollection = async() => {
    if (show) {
      const res = await axios.get(`${appConstants.COLLECTIONS_URL}${props.id}.json`)
      setCollection(res.data)
      setTpmSummaryReport(res.data["tpm_summary_report"])
      setVirtualTour(res.data["virtual_tour"])
      setVideo(res.data["video"])
      setCollectionResources(res.data["resources"])
      setCollectionPhotos(res.data["photos"].map(i => {
        const g = {}
        g["original"] = i.url
        g["description"] = i.name
        return g
      }))
      setTpmMaps(res.data["tpm_maps"].map(i => {
        const g = {}
        g["original"] = i.url
        g["description"] = i.name
        return g
      }))
    }
  }

  useEffect(() => {
    loadCollection()
  }, [props.id, props.show])

  const openResource = (r) => {
    setShow(false);
    if (props.openResource) {
      props.openResource(r)
    } else {
      return window.location.href = r.direct_link
    }
  }

  const renderEditButton = (c) => {
    return c.can_edit && !props.hideButtons ? (
      <Button variant="outline-primary" href={`${appConstants.COLLECTIONS_URL}${props.id}/edit`}>{I18n.t('navigation.edit')}</Button>
    ) : ""
  }

  const renderDeleteButton = (c) => {
    return c.can_delete && !props.hideButtons ? (
      <Button variant="outline-danger" href={`${appConstants.COLLECTIONS_URL}${props.id}/delete`}>{I18n.t('navigation.delete')}</Button>
    ) : ""
  }

  const renderPDFViewer = (r) => {
    const url = r.file_url ? r.file_url : r.url
    const extension = url ? url.substring(url.lastIndexOf('.') + 1) : ""
    
    return extension == 'pdf' ? (
      <Row>
        <PDFCol md={12}>
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} height={1100} scale={2} renderTextLayer={false} />
          </Document>
          <div>
            <Button
              variant="outline-secondary"
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <BiChevronLeft />
            </Button>
              <span className="text-muted">&nbsp;Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}&nbsp;</span>
            <Button
              variant="outline-secondary"
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <BiChevronRight />
            </Button>
          </div>
        </PDFCol>
      </Row>
    ) : ""
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h5>{collection.description}</h5>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {virtualTour ? <Iframe source={virtualTour.url} height={'600px'} width={'100%'} /> : ''}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card style={{ width: '100%', marginBottom: '20px' }}>
            {(video && !virtualTour) ? <video controls><source src={video.url} type="video/mp4"/>Your browser does not support HTML5 video.</video> : ''}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={collectionPhotos.length > 0 ? 4 : 12}>
          <Row>
            <Col md={12}>
              {tpmMaps.length > 0 ? <ImageGallery showThumbnails={false} items={tpmMaps} /> : ''}
            </Col>
            <Col md={12}>
                <br/>
                <b>{I18n.t('activerecord.attributes.collection.resources')}</b>
                <ListGroup variant="flush">
                  {collectionResources.map((r) => <ListGroup.Item key={r.id} action onClick={()=>openResource(r, props.id)}>{r.name}</ListGroup.Item>)}
                </ListGroup>
            </Col>
          </Row>
        </Col>
        <Col md={collectionPhotos.length > 0 ? 8 : 0}>
          {collectionPhotos.length > 0 ? <ImageGallery showThumbnails={false} items={collectionPhotos} /> : ''}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
        </Col>
      </Row>
      {tpmSummaryReport ? renderPDFViewer(tpmSummaryReport) : ""}
      <Row>
        <Col md={12}>
          <hr/>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          {renderEditButton(collection)} 
        </Col>
        <Col md={2}>
          {renderDeleteButton(collection)}
        </Col>
      </Row>
    </Container>
  )
}
