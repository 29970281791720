import React, { Component }  from 'react'
import styled from 'styled-components'
import moment from 'moment'
import MonitoringRequestFilters from './filters/MonitoringRequestFilters'
import SurveyDetails from './SurveyDetails.js'
import { setReport, scoreColors } from 'utils/constants.js'
import I18n from 'i18n-js/locales.js'
import Form from 'react-bootstrap/Form'
import Collapse from 'react-bootstrap/Collapse'
import { BsBoxArrowDown, BsBoxArrowUp, BsInfoCircle, BsInfoCircleFill } from 'react-icons/bs'
import EventMap from './maps/EventMap.js'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Modal } from 'react-bootstrap'


const InfoButton = styled.div`
  right: 2em;
  top: 2em;
  position: absolute;
  z-index: 200;
  cursor: pointer;
  color: #205492;
  `;

const FiltersButton = styled.div`
  left: .5em;
  top: .1em;
  position: absolute;
  z-index: 0;
  cursor: pointer;
  color: #205492;
  `;

const MapOptions = styled.div`
  right: .5em;
  position: absolute;
  z-index: 500;
  background-color: gainsboro;
  opacity: .8;
  color:black;
  margin: 1em;
  border-radius: .5em;
  padding: .5em;
  font-size: small;
  `;

export default class ContextResults extends Component {

  state = {
    initialData: this.props.initialData.data || [],
    filteredData: this.props.initialData.data || [],
    filters: this.props.initialData.filters || [],
    selectedReport: null,
    showFilters: true,
    mapClusters: false,
    showInfo: false,
  }

  // filtering functions
  handleFilterChange = (e, filterBy, clickClear) => {
    const filters = this.state.filters
    let filterValue = null
    if (e && (this.state.filters[filterBy] != e.value || !clickClear)) { filterValue = e.value } 
    const newFilters = {
      ...filters,
      [filterBy]: filterValue,
    }
    this.filterData(newFilters)
  }

  handleMapClusterChange = () => {
    this.setState(prevState => ({
      mapClusters: !prevState.mapClusters
    }));
  }

  // TODO: extract exception cases from this function, keep generic
  filterData = (filters) => {
    const data = this.state.initialData
    const filterKeys = Object.keys(filters)
    let filteredData = [...data]
    for (let filterKey of filterKeys) {
      if (filters[filterKey] !== null && filterKey === 'total_score') {
        filteredData = filteredData.filter(task => (Math.round(task[filterKey]).toFixed(2) >= filters[filterKey][0] && Math.round(task[filterKey]).toFixed(2) < filters[filterKey][1]) )
      } else if (filters[filterKey] !== null && filterKey !== "days_range") {
        if (filters[filterKey] == -1) {
          filteredData = filteredData.filter(task => (task[filterKey] == "" || task[filterKey] == null) )
        } else {
          filteredData = filteredData.filter(task => task[filterKey] == filters[filterKey] )
        }
      } else if (filterKey == "days_range") {
        const today = new Date()
        let latest = new Date()
        let earliest = new Date()
        latest.setDate(today.getDate() - filters[filterKey][0])
        earliest.setDate(today.getDate() - filters[filterKey][1])
        filteredData = filteredData.filter(task => {
          const visit = new Date(task["visit_date"])
          return visit > earliest && visit < latest
        })
      }
    }
    
    const newState = {
      ...this.state,
      filteredData: filteredData,
      filters: filters,
    }
    this.setState(newState)
  }

  componentDidMount = () => {
    this.filterData({'district_id': null, 'days_range': [0,30], 'total_score': null})
  }

  // for use with detail sidebar
  setSelectedReport = (task) => {
    const report = setReport(task)
    this.setState({
      selectedReport: report,
    })
  }

  taskContent = (task) => {
    return (
      <div>
        <strong><a href='#' onClick={() => {this.setSelectedReport(task)}}>{task.name}</a></strong> <br/>
        {I18n.t('activerecord.attributes.context_report.total_score')}: {parseFloat(task.total_score).toFixed(2)} <br/>
      </div>
    )
  }

  closeInfoModal = () => {
    this.setState({
      showInfo: false
    })
  }

  renderFiltersButton() {
    return (
      <FiltersButton 
        onClick={() => this.setState({showFilters: !this.state.showFilters})} 
        aria-controls="collapse-filters" 
        aria-expanded={this.state.showFilters}>
          <h4>{this.state.showFilters ? <BsBoxArrowUp /> : <BsBoxArrowDown />}</h4>
      </FiltersButton>
    ) 
  }

  renderInfoButton() {
    return (
      <InfoButton
        onClick={() => this.setState({showInfo: !this.state.showInfo})}
        aria-controls="collapse-info"
        aria-expanded={this.state.showInfo}>
          <h4>{this.state.showInfo ? <BsInfoCircleFill /> : <BsInfoCircle />}</h4>
      </InfoButton>
    )
  }
  
  renderContextReportDetails() {
    return this.state.selectedReport ? <SurveyDetails id={this.state.selectedReport.id} /> : ""
  }

  renderDashboard() {
    return (
      <>
        <Row>
          <Col md={7}>
            <Form>
              <MapOptions>
                <Form.Check
                  inline
                  label={I18n.t('headers.map.show_clusters')}
                  type="checkbox"
                  name="clusters"
                  checked={this.state.mapClusters}
                  value={true}
                  onChange={this.handleMapClusterChange}
                  id="1" />
              </MapOptions>
            </Form>
            {this.renderMap()}
          </Col>
          <Col md={5}>
          {this.renderContextReportDetails()}
          </Col>
        </Row>
      </>
    )
  }

  renderMap() {
    return (
      <EventMap
        data={this.state.filteredData}
        markerContent={this.taskContent}
        filter_id={"total_score"}
        show_clusters={this.state.mapClusters}
        legendItems={scoreColors}
      />
      ) 
  }

  renderInfoModal() {
    return (
      <Modal
        show={this.state.showInfo}
        onHide={this.closeInfoModal}
        keyboard={true}
        size={'l'}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.t('context_monitoring.info_header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span dangerouslySetInnerHTML={{__html: I18n.t('context_monitoring.info') }}></span>
        </Modal.Body>
      </Modal>
    )
  }

  render() {
    return (
      <>
        {this.renderInfoModal()}
        <div>
          <Collapse in={this.state.showFilters}>
            <div id="collapse-filters">
              <MonitoringRequestFilters handleFilterChange={this.handleFilterChange} includedFilters={this.state.filters} excludeSearch={true} page={"cm"} />
            </div>
          </Collapse>
          {this.renderInfoButton()}
          {this.renderFiltersButton()}
        </div>
        <div>
          {this.renderDashboard()}
        </div>
      </>
    )
  }
}

