import React from 'react'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import SelectSingle from '../selects/SelectSingle.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import ZoneForm from '../forms/ZoneForm.js'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'
import { appConstants } from 'utils/constants.js'
import { formatValidationErrors } from 'utils/format.js'
import { BiPlus } from "react-icons/bi";

export default class MonitoringRequestForm extends React.Component {
  state = {
    id: this.props.monitoring_request.id || "",
    name: this.props.monitoring_request.name || "",
    description: this.props.monitoring_request.description || "",
    request_status: this.props.monitoring_request.request_status || "",
    start_date: this.props.monitoring_request.start_date ? moment(this.props.monitoring_request.start_date) : null,
    end_date: this.props.monitoring_request.end_date ? moment(this.props.monitoring_request.end_date) : null,
    date_requested: this.props.monitoring_request.date_requested ? moment(this.props.monitoring_request.date_requested) : moment(),
    date_submitted: this.props.monitoring_request.date_submitted ? moment(this.props.monitoring_request.date_submitted) : null,
    user_id: this.props.monitoring_request.user_id || "",
    reviewer_id: this.props.monitoring_request.reviewer_id || "",
    activity_id: this.props.monitoring_request.activity_id || "",
    implementing_partner_id: this.props.monitoring_request.implementing_partner_id || "",
    governorate_id: this.props.monitoring_request.governorate_id || "",
    district_id: this.props.monitoring_request.district_id || "",
    territory_id: this.props.monitoring_request.territory_id || "",
    zone_id: this.props.monitoring_request.zone_id || "",
    office_id: this.props.monitoring_request.office_id || "",
    sector_id: this.props.monitoring_request.sector_id || "",
    virtual: this.props.monitoring_request.virtual || false,
    hold: this.props.monitoring_request.hold || false,
    latitude: this.props.monitoring_request.latitude || 0,
    longitude: this.props.monitoring_request.longitude || 0,
    request_justification: this.props.monitoring_request.request_justification || "",
    request_justification_details: (this.props.monitoring_request.data && this.props.monitoring_request.data["request_justification_details"]) ? this.props.monitoring_request.data["request_justification_details"] : "",
    poc: (this.props.monitoring_request.data && this.props.monitoring_request.data["poc"]) ? this.props.monitoring_request.data["poc"] : "",
    mode_of_transport: (this.props.monitoring_request.data && this.props.monitoring_request.data["mode_of_transport"]) ? this.props.monitoring_request.data["mode_of_transport"] : "",
    notes: (this.props.monitoring_request.data && this.props.monitoring_request.data["notes"]) ? this.props.monitoring_request.data["notes"] : "",
    beneficiaries: (this.props.monitoring_request.data && this.props.monitoring_request.data["beneficiaries"]) ? this.props.monitoring_request.data["beneficiaries"] : "",
    request_type: this.props.monitoring_request.request_type || "",
    requested_focused: false,
    start_focused: false,
    end_focused: false,
    submitted_focused: false,
    tag_ids: this.props.monitoring_request.tags || [],
    subsector_ids: this.props.monitoring_request.subsectors || [],
    alertMessage: "",
    showAddZoneModal: false,
    showAddZoneButton: this.props.monitoring_request.territory_id ? true : false,
  }

  createRequestData = async function()  {
    let formData = new FormData()
    formData.append('monitoring_request[name]', this.state.name)
    formData.append('monitoring_request[description]',  this.state.description)
    formData.append('monitoring_request[start_date]', this.state.start_date) 
    formData.append('monitoring_request[end_date]', this.state.end_date)
    formData.append('monitoring_request[date_requested]', this.state.date_requested) 
    formData.append('monitoring_request[date_submitted]', this.state.date_submitted)
    formData.append('monitoring_request[hold]',  this.state.hold)
    formData.append('monitoring_request[latitude]', this.state.latitude)
    formData.append('monitoring_request[longitude]', this.state.longitude)
    formData.append('monitoring_request[poc]', this.state.poc)
    formData.append('monitoring_request[mode_of_transport]', this.state.mode_of_transport)
    formData.append('monitoring_request[notes]', this.state.notes)
    formData.append('monitoring_request[beneficiaries]', this.state.beneficiaries)
    formData.append('monitoring_request[request_justification]', this.state.request_justification)
    formData.append('monitoring_request[request_justification_details]', this.state.request_justification_details)
    formData.append('monitoring_request[virtual]', this.state.virtual || false)
    formData.append('monitoring_request[user_id]', this.state.user_id || "")
    formData.append('monitoring_request[reviewer_id]', this.state.reviewer_id || "")
    formData.append('monitoring_request[activity_id]', this.state.activity_id || "")
    formData.append('monitoring_request[implementing_partner_id]', this.state.implementing_partner_id || "")
    formData.append('monitoring_request[district_id]', this.state.district_id || "")
    formData.append('monitoring_request[territory_id]', this.state.territory_id || "")
    formData.append('monitoring_request[zone_id]', this.state.zone_id || "")
    formData.append('monitoring_request[office_id]', this.state.office_id || "")
    formData.append('monitoring_request[sector_id]', this.state.sector_id || "")
    formData.append('monitoring_request[request_type]', this.state.request_type || "")
    this.state.tag_ids.forEach(tag_id => {
      formData.append('monitoring_request[tag_ids][]', tag_id)
    })
    this.state.subsector_ids.forEach(subsector_id => {
      formData.append('monitoring_request[subsector_ids][]', subsector_id)
    })

    return formData
  }

  createDuplicate = () => {
    this.setState({
      id: null
    }, () => {
      this.handleSubmit()
    })
  }

  handleSubmit = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    if (this.state.id) {
      this.createRequestData().then((monitoring_request) => {
        axios.put(`${appConstants.MONITORING_EVENT_URL}${this.state.id}`, monitoring_request )
        .then(res => {
          this.setState({ alertMessage: I18n.t("notices.update_success") });
          this.props.refreshData()
        })
        .catch(error => {
          this.setState({ alertMessage: formatValidationErrors('monitoring_request', error.response.data) });
        })
      })
    } else {
      this.createRequestData().then((monitoring_request) => {
        axios.post(appConstants.MONITORING_EVENT_URL,  monitoring_request )
        .then(res => {
          this.setState({ alertMessage: I18n.t("notices.create_success") });
          this.setState({id: res.data.id})
          this.props.refreshData()
        })
        .catch(error => {
          this.setState({ alertMessage: formatValidationErrors('monitoring_request', error.response.data) });
        })
      })
    }
  }

  handleDelete = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;
    axios.delete(`${appConstants.MONITORING_EVENT_URL}/${this.state.id}.json`)
    .then(res => {
      this.setState({ alertMessage: I18n.t("notices.delete_success") });
      this.props.closeModal()
      this.props.refreshData()
    })
    .catch(error => {
      this.setState({ alertMessage: I18n.t("notices.delete_failure") });
    })
  }

  handleAlertMessage = () => {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          <span dangerouslySetInnerHTML={{__html: this.state.alertMessage}}></span>
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  };

  handleInputChange = (e) => {
    const target = e.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value
    })
  }

  handleCheckbox = (e) => {
    const target = e.target
    const name = target.name
    const value = !this.state[name]
    this.setState({
      [name]: value
    })
  }

  handleMultiChange = (e, field) => {
    const ids = e != null ? e.map(i => i.value) : []
    this.setState({
      [field]: ids
    }, () => this.updateDependentSelected(field))
  }

  handleSelectChange = (e, selectName) => {
    let selectValue = null
    if (e) { selectValue = e.value } 
    this.setState({
      [selectName]: selectValue
    }, () => this.updateDependentSelected(selectName))
  }

  updateDependentSelected = (selectName) => {
    if (selectName === 'activity_id') {
      this.setState({ implementing_partner_id: null })
    }

    if (selectName === 'governorate_id') {
      this.setState({ district_id: null })
    }

    if (selectName === 'sector_id') {
      this.setState({ subsector_ids: [] })
    }

    if (selectName === 'territory_id') {
      this.setState({ showAddZoneButton: true })
    }
  }

  showAddZoneModal = () => {
    this.state.territory_id ? this.setState({showAddZoneModal: true}) : this.setState({alertMessage: I18n.t('zone.choose_territory_first')})
  }

  closeAddZoneModal = (z) => {
    if (z) { this.setState({ showAddZoneModal: false, zone_id: z, alertMessage: "" }) }
  }

  renderDuplicateButton() {
    if (this.state.id) {
      return (<Button variant="outline-primary" onClick={this.createDuplicate}>{I18n.t("navigation.duplicate")}</Button> )
    }
  }

  renderDeleteButton() {
    return (<Button variant="outline-danger" style={{float: 'right'}} onClick={this.handleDelete}>{I18n.t("navigation.delete")}</Button>)
  }

  renderSubmitDate() {
    return (
      <>
      <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.date_submitted')}</Form.Label><br/>
        <SingleDatePicker
          date={this.state.date_submitted}// momentPropTypes.momentObj or null
          onDateChange={date_submitted => this.setState({ date_submitted })} // PropTypes.func.isRequired
          focused={this.state.submitted_focused} // PropTypes.bool
          onFocusChange={({ focused: submitted_focused }) => this.setState({ submitted_focused })} // PropTypes.func.isRequired
          id="date_submitted" // PropTypes.string.isRequired,
          isOutsideRange={()=>false}
        />
      </>
    )
  }

  renderAddZoneButton() {
    return this.state.showAddZoneButton ? (
      <Button variant="outline-secondary" onClick={this.showAddZoneModal} className="add-collection-btn" title={I18n.t('zone.add')}><BiPlus /></Button>
    ) : ""
  }

  render() {
    return this.state.showAddZoneModal ? (
      <ZoneForm
        authenticityToken={this.props.authenticityToken}
        zone={{territory_id: this.state.territory_id}}
        isModal={true}
        closeModal={this.closeAddZoneModal}
        onHide={this.closeAddZoneModal} />
    ) : (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Form.Row>
          <Form.Group as={Col} md={12} controlId="requestForm.name">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.name')}</Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6} controlId="requestForm.governorate">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.governorate')}</Form.Label>
            <SelectSingle
              selected={this.state.governorate_id}
              onValueChange={(e) => this.handleSelectChange(e, "governorate_id")}
              url={appConstants.GOVERNORATES_URL}
              isClearable={true}
              placeholder={I18n.t('selects.single')} />
          </Form.Group>

          <Form.Group as={Col} md={6} controlId="requestForm.district">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.district')}</Form.Label>
            <SelectSingle
              selected={this.state.district_id}
              onValueChange={(e) => this.handleSelectChange(e, "district_id")}
              url={appConstants.DISTRICTS_BY_GOVERNORATE_URL + (this.state.governorate_id || -1) + ".json"}
              placeholder={I18n.t('selects.single')}
              isClearable={true}
              isDependent={true} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6} controlId="requestForm.territory">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.territory')}</Form.Label>
            <SelectSingle
              selected={this.state.territory_id}
              onValueChange={(e) => this.handleSelectChange(e, "territory_id")}
              url={appConstants.TERRITORIES_BY_DISTRICT_URL + (this.state.district_id || -1) + ".json"}
              placeholder={I18n.t('selects.single')}
              isClearable={true}
              isDependent={true} />
          </Form.Group>

          <Form.Group as={Col} md={4} controlId="requestForm.zone">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.zone')}</Form.Label> &nbsp;
            {this.renderAddZoneButton()}
            <SelectSingle
              selected={this.state.zone_id}
              onValueChange={(e) => this.handleSelectChange(e, "zone_id")}
              url={appConstants.ZONES_BY_TERRITORY_URL + (this.state.territory_id || -1) + ".json"}
              placeholder={I18n.t('selects.single')}
              isClearable={true}
              isDependent={true} />
          </Form.Group>
          <Form.Group as={Col} md={1} controlId="requestForm.latitude">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.latitude')}</Form.Label>
            <Form.Control
              name="latitude"
              type="text"
              value={this.state.latitude}
              onChange={this.handleInputChange} />
          </Form.Group>

          <Form.Group as={Col} md={1} controlId="requestForm.longitude">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.longitude')}</Form.Label>
            <Form.Control
              name="longitude"
              type="text"
              value={this.state.longitude}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6} controlId="requestForm.sector">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.sector')}</Form.Label>
            <SelectSingle
              selected={this.state.sector_id}
              onValueChange={(e) => this.handleSelectChange(e, "sector_id")}
              url={appConstants.SECTORS_URL}
              placeholder={I18n.t('selects.single')}
              isClearable={true} />
          </Form.Group>

          <Form.Group as={Col} md={6} controlId="requestForm.subsector">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.subsectors')}</Form.Label>
            <SelectMultiple
              selected={this.state.subsector_ids}
              onChange={(e) => this.handleMultiChange(e, 'subsector_ids')}
              url={appConstants.SUBSECTORS_URL + "?sector_id=" + (this.state.sector_id || -1)}
              placeholder={I18n.t('selects.multiple_optional')}
              groupedName='subsectors'
              isDependent={true} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6} controlId="requestForm.office">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.office')}</Form.Label>
            <SelectSingle
              selected={this.state.office_id}
              onValueChange={(e) => this.handleSelectChange(e, "office_id")}
              url={appConstants.OFFICES_URL}
              placeholder={I18n.t('selects.single')}
              isClearable={true} />
          </Form.Group>

          <Form.Group as={Col} md={6} controlId="requestForm.ip">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.activity')}</Form.Label>
            <SelectSingle
              selected={this.state.activity_id}
              onValueChange={(e) => this.handleSelectChange(e, "activity_id")}
              url={appConstants.ACTIVITIES_URL+'.json'}
              placeholder={I18n.t('selects.single')}
              isClearable={true} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={2} controlId="requestForm.start_date">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.start_date')}</Form.Label><br/>
            <SingleDatePicker
              date={this.state.start_date}// momentPropTypes.momentObj or null
              onDateChange={start_date => this.setState({ start_date })} // PropTypes.func.isRequired
              focused={this.state.start_focused} // PropTypes.bool
              onFocusChange={({ focused: start_focused }) => this.setState({ start_focused })} // PropTypes.func.isRequired
              id="start_date" // PropTypes.string.isRequired,
              isOutsideRange={()=>false}
            />
          </Form.Group>

          <Form.Group as={Col} md={2} controlId="requestForm.date_of_report">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.end_date')}</Form.Label><br/>
            <SingleDatePicker
              date={this.state.end_date}// momentPropTypes.momentObj or null
              onDateChange={end_date => this.setState({ end_date })} // PropTypes.func.isRequired
              focused={this.state.end_focused} // PropTypes.bool
              onFocusChange={({ focused: end_focused }) => this.setState({ end_focused })} // PropTypes.func.isRequired
              id="date_of_report" // PropTypes.string.isRequired,
              isOutsideRange={()=>false}
              disabled={true}
            />
          </Form.Group>
            
          <Form.Group as={Col} md={2} controlId="requestForm.virtual">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.virtual')}</Form.Label>
            <Form.Check
              type="checkbox"
              name="virtual"
              checked={this.state.virtual}
              value={true}
              onChange={this.handleCheckbox}
              bsPrefix="virtual-checkbox" />
          </Form.Group>

          <Form.Group as={Col} md={6} controlId="requestForm.description">
            <Form.Label>{I18n.t('activerecord.attributes.monitoring_request.description')}</Form.Label>
            <Form.Control
              type="text"
              name="description"
              value={this.state.description}
              onChange={this.handleInputChange} />
          </Form.Group>
        </Form.Row>
 
        <Form.Group  controlId="resourceForm.buttons">
          <Button variant="outline-primary" onClick={this.handleSubmit}>{this.state.id ? I18n.t("navigation.update") : I18n.t("navigation.add")}</Button> &nbsp;
          {this.renderDuplicateButton()} &nbsp;
          <ButtonGroup toggle>
            <ToggleButton
              type="checkbox"
              name="hold"
              variant="outline-danger"
              checked={this.state.hold}
              value={true}
              onChange={this.handleCheckbox}
            >
              {I18n.t('activerecord.attributes.monitoring_request.security_hold')}
            </ToggleButton>
          </ButtonGroup>
          {this.renderDeleteButton()}
        </Form.Group>
      </Form>
    );
  };
}