import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import I18n from 'i18n-js/locales.js'
import { formatValidationErrors } from 'utils/format.js'
import { appConstants } from 'utils/constants.js'
import SelectMultiple from '../selects/SelectMultiple.js'
import SelectSingle from '../selects/SelectSingle.js'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button' 

class CollectionForm extends React.Component {
  static defaultProps = {
    collection: {
      id: "",
      title: "",
      description: "",
      resources: [],
      monitoring_request_id: null,
    },
    isModal: false,
    closeModal: () => {},
  }

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.collection.id || "",
      title: this.props.collection.name || "",
      description: this.props.collection.description || "",
      isFeatured: this.props.collection.is_featured || false,
      resourceIDs: this.props.collection.resources.map(i => (i.id)) || [],
      monitoringRequestId: this.props.collection.monitoring_request_id || "",
      alertMessage: "",
    }

    this.handleCollectionSubmit = this.handleCollectionSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResourcesChange = this.handleResourcesChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleMonitoringRequestChange = this.handleMonitoringRequestChange.bind(this);
  }

  handleClose(e) {
    this.props.closeModal(e);
  }

  handleCollectionSubmit(e) {
    e.preventDefault();
    axios.defaults.headers.common["X-CSRF-TOKEN"] = this.props.authenticityToken;

    let formData = new FormData()
    formData.append('collection[name]', this.state.title)
    formData.append('collection[description]', this.state.description)
    formData.append('collection[is_featured]', this.state.isFeatured)
    formData.append('collection[monitoring_request_id]',  this.state.monitoringRequestId)
    this.state.resourceIDs.forEach(resourceId => {
      formData.append('collection[resource_ids][]', resourceId)
    })

    if (this.state.id) {
      axios.put(`${appConstants.COLLECTIONS_URL}${this.state.id}`, formData)
      .then(res => {
        return window.location.href = `${appConstants.SEARCH_URL}`
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('collection', error.response.data) });
      })
    } else {
      axios.post(`${appConstants.COLLECTIONS_URL}`, formData)
      .then(res => {
        this.handleClose(res.data.id);
        if (!this.props.isModal) {
          return window.location.href = appConstants.SEARCH_URL
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ alertMessage: formatValidationErrors('collection', error.response.data) });
      })
    }
  }

  handleMonitoringRequestChange(e) {
    e ? this.setState({monitoringRequestId: e.value}) : this.setState({monitoringRequestId: ""})
  }

  handleInputChange(e) {
    const target = e.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    });
  }

  handleCheckbox(e) {
    const target = e.target
    const name = target.name
    const value = !this.state[name]
    this.setState({
      [name]: value
    })
  }
  
  handleAlertMessage() {
    if (this.state.alertMessage){
      return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {this.state.alertMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    } else {
      return ""
    }
  }

  handleResourcesChange(e) {
    const resourceIDs = e != null ? e.map(t => t.value) : []
    this.setState({
      resourceIDs: resourceIDs
    })
  }

  render() {
    return (
      <Form>
        <Row>
          <Col>
            {this.handleAlertMessage()}
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>{I18n.t('activerecord.attributes.collection.name')}<span className="text-danger">*</span></Form.Label>
          <Form.Control
            name="title"
            type="text"
            value={this.state.title}
            onChange={this.handleInputChange} />
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} md={12}>
            <Form.Label>{I18n.t('activerecord.attributes.collection.description')}</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={this.state.description}
              onChange={this.handleInputChange}
              rows="2" />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={5}>
            <Form.Label>{I18n.t('activerecord.attributes.resource.monitoring_request')}</Form.Label>
            <SelectSingle
              selected={this.state.monitoringRequestId}
              onValueChange={this.handleMonitoringRequestChange}
              url={appConstants.REQUESTS_FOR_COLLECTIONS_URL + '?monitoring_request_id=' + this.state.monitoringRequestId}
              placeholder={I18n.t('selects.single_optional')}
              isClearable={true} />
          </Form.Group>

          <Form.Group as={Col} md={5}>
            <Form.Label>{I18n.t('activerecord.attributes.collection.resources')}</Form.Label>
            <SelectMultiple
              selected={this.state.resourceIDs}
              onChange={this.handleResourcesChange}
              url={appConstants.RESOURCES_JSON_URL}
              placeholder={I18n.t('selects.multiple_optional')}  />
          </Form.Group>
          <Form.Group as={Col} md={2}>
              <Form.Label>{I18n.t('activerecord.attributes.collection.is_featured')}</Form.Label>
              <Form.Check
                type="checkbox"
                name="isFeatured"
                checked={this.state.isFeatured}
                value={true}
                onChange={this.handleCheckbox}
                bsPrefix="virtual-checkbox"/>
            </Form.Group>
        </Form.Row>
        <Form.Group>
          <Button variant="outline-primary" onClick={this.handleCollectionSubmit}>{this.state.id ? I18n.t('navigation.update') : I18n.t('navigation.add')}</Button> &nbsp;
          <Button variant="outline-secondary" href={this.props.isModal ? "#" : appConstants.SEARCH_URL} onClick={this.handleClose}>{I18n.t('navigation.cancel')}</Button>
        </Form.Group>
      </Form>
    );
  }
}

CollectionForm.propTypes = {
  collection: PropTypes.object,
  authenticityToken: PropTypes.string,
  isModal: PropTypes.bool,
  closeModal: PropTypes.func,
}

export default CollectionForm