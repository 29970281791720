import React from 'react'
import styled from 'styled-components'
import { BiAlarm, BiAlarmExclamation } from 'react-icons/bi'
import moment from 'moment'
import { BsFlagFill } from 'react-icons/bs'

export const chartColors = ["#8CFDB4","#ECAFB5","#89C7FF","#301171","#1A8413","#899214","#4559C7","#A60B46",
    "#E17840","#25802B","#26767E","#DCCF49","#F4DC04",  "#3AA4E4","#26B52F","#F68EA8","#D10D79","#86C5DA","#627C9B",
    "#CFE7A1","#6A7D3C","#A441D5","#DBC7FF",'#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', 
    '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', 
    '#000075', '#808080',]

export const scoreColors = {
  "0": {name: "0.0 - 0.49", color: "#f40606"},
  "1": {name: "0.5 - 1.49", color: "#dc5805"},
  "2": {name: "1.5 - 2.49", color: "#c49804"},
  "3": {name: "2.5 - 3.49", color: "#91ac03"},
  "4": {name: "3.5 - 4.49", color: "#459303"},
  "5": {name: "4.5 - 5.0", color: "#0b7b02"}
}

export const appConstants = {
  MONITORING_EVENT_URL: '/tpm/monitoring_requests/',
  CONTEXT_REPORT_URL: '/tpm/context_reports/',
  PLANNING_URL: '/tpm/planning.json',
  ARCHIVE_URL: '/tpm/planning/archive.json',
  RESULTS_URL: '/tpm/results.json',
  CALENDAR_URL: '/tpm/events_calendar/',
  REVIEW_BOARD_URL: '/tpm/visits',
  USERS_URL: '/users.json',
  MONITORS_URL: '/users.json?job=monitor',
  REVIEWERS_URL: '/users.json?job=reviewer',
  USAID_USERS_URL: '/users.json?job=security',
  EVENT_STATUSES_URL: '/tpm/request_statuses',
  ARCHIVE_STATUSES_URL: '/tpm/request_statuses?archive=true',
  IFM_STATUSES_URL: '/tpm/request_statuses?ifm=true',
  EVENT_MODES_URL: '/tpm/request_modes',
  ACTIVITIES_URL: '/tpm/implementing_partners',
  ACTIVITIES_ADMIN_LIST_URL: '/admin_activities_list',
  IMPLEMENTING_PARTNERS_URL: '/tpm/cooperating_partners',
  IPS_BY_ACTIVITY_URL: '/tpm/ips_by_activity/',
  IPS_URL: '/tpm/cooperating_partners.json',
  ACTIVITIES_BY_IP_URL: '/tpm/activities_by_ip/',
  GOVERNORATES_URL: '/tpm/governorates.json',
  GOVERNORATES_WITH_TPM_DATA_URL: '/tpm/governorates.json?tpm=true',
  DISTRICTS_URL: '/tpm/districts.json',
  DISTRICTS_WITH_CM_DATA_URL: '/tpm/districts.json?cm=true',
  DISTRICTS_WITH_TPM_DATA_URL: '/tpm/districts.json?tpm=true',
  DISTRICTS_BY_GOVERNORATE_URL: '/tpm/districts_by_governorate/',
  TERRITORIES_URL: '/tpm/territories.json',
  TERRITORIES_BY_DISTRICT_URL: '/tpm/territories_by_district/',
  ZONES_URL: '/tpm/zones.json',
  ZONES_BY_TERRITORY_URL: '/tpm/zones_by_territory/',
  SECTORS_URL: '/tpm/sectors.json',
  SUBSECTORS_URL: '/tpm/subsectors.json',
  VERIFICATIONS_FOR_MONITORING_REQUEST_URL: '/tpm/verifications_for_monitoring_request/',
  CONTEXT_REPORTS_URL: '/tpm/context_reports.json',
  RESOURCES_FOR_MONITORING_REQUEST_URL: '/kmp/resources_for_monitoring_request/',
  IMAGES_FOR_MONITORING_REQUEST_URL: '/kmp/images_for_monitoring_request/',
  FILTERED_RESOURCES_URL: '/kmp/filtered_resources',
  JUSTIFICATIONS_URL: '/tpm/request_justifications',
  TIME_INTERVALS_URL: '/tpm/time_intervals',
  COLLECTIONS_URL: '/kmp/collections/',
  RESOURCES_URL: '/kmp/resources/',
  SEARCH_URL: '/kmp/search/',
  COLLECTIONS_JSON_URL: '/kmp/collections.json',
  RESOURCES_JSON_URL: '/kmp/resources.json',
  GROUPED_TAGS_URL: '/kmp/grouped_tags/',
  MONITORING_TAGS_URL: '/kmp/grouped_tags?monitoring_request_tags=true',
  REQUESTS_FOR_RESOURCES_URL: '/tpm/requests_for_resources/',
  REQUESTS_FOR_COLLECTIONS_URL: '/tpm/requests_for_collections',
  IMPORT_EVENTS_URL: '/tpm/bulk_import',
  OFFICES_URL: '/tpm/offices.json',
  PHOTOS_FOR_CONTEXT_URL: '/tpm/context_reports/photos/',
  NEW_RESOURCE_URL: '/kmp/resources/new',
  NEW_COLLECTION_URL: '/kmp/collections/new',
  THEMATIC_SURVEYS_URL: '/tpm/thematic_dashboard.json',
  FLAGS_URL: '/tpm/flags',
  FLAGGABLE_REQUESTS_URL: '/tpm/flaggable_requests.json',
  FLAG_PRIORITIES_URL: '/tpm/flag_priorities.json',
  FLAG_STATUSES_URL: '/tpm/flag_statuses.json',
  TOTAL_SCORES_URL: '/tpm/context_total_scores.json',
  FULCRUM_URL: 'https://web.fulcrumapp.com/',
  TPM_QUESTIONS_URL: '/tpm/tpm_questions/',
  TPM_ANSWER_TYPES_URL: '/tpm/answer_types/',
  FULCRUM_FORMS_URL: '/tpm/fulcrum_forms',
  QUESTION_IMPORT_URL: '/tpm/questions_import',
  REQUEST_TYPES_URL: '/tpm/request_types',
}

export const setRequest = (e) => {
  const request = {
    id: parseInt(e.id),
    name: e.name,
    request_status: e.request_status_id,
    request_justification_name: e.request_justification_name,
    request_justification: e.request_justification,
    request_type: e.request_type,
    request_type_label: e.request_type_label,
    start_date: e.start_date,
    end_date: e.end_date,
    date_requested: e.date_requested,
    date_submitted: e.date_submitted,
    user_id: e.user_id,
    reviewer_id: e.reviewer_id,
    activity_id: e.activity_id,
    activity_name: e.activity_name,
    implementing_partner_id: e.implementing_partner_id,
    implementing_partner_name: e.implementing_partner_name,
    governorate_id: e.governorate_id,
    governorate_name: e.governorate_name,
    district_id: e.district_id,
    district_name: e.district_name,
    territory_id: e.territory_id,
    zone_id: e.zone_id,
    office_id: e.office_id,
    sector_id: e.sector_id,
    subsectors: e.subsectors,
    virtual: e.virtual,
    hold: e.hold,
    latitude: e.latitude,
    longitude: e.longitude,
    start_week: e.start_week,
    tags: e.tags,
    description: e.description,
    data: {
      poc: e.poc,
      mode_of_transport: e.mode_of_transport,
      notes: e.notes,
      beneficiaries: e.beneficiaries,
      request_justification_details: e.request_justification_details,
    }
  }
  return request
}

export const setReport = (e) => {
  const report = {
    id: parseInt(e.id),
    name: e.name,
    longitude: e.longitude,
    latitude: e.latitude,
    total_score: e.total_score,
    external_id: e.external_id,
  }
  return report
}

export const setResource = (e) => {
  const resource = {
    id: parseInt(e.id),
    name: e.name,
    url: e.url,
    description: e.description,
    is_public: e.is_public,
  }
  return resource
}

export const setFlag = (e) => {
  const flag = {
    id: parseInt(e.id),
    name: e.name,
    flag_priority: e.flag_priority,
    monitoring_request_id: e.monitoring_request_id,
    description: e.description,
    due_date: e.due_date,
    user_id: e.user_id,
    flag_status: e.flag_status,
  }
  return flag
}

const YellowFlag = styled(BsFlagFill)`
  color: #ffde20;
  `;

const OrangeFlag = styled(BsFlagFill)`
  color: #f48000;
  `;

const RedFlag = styled(BsFlagFill)`
  color: #d90000;
  `;

export const renderFlag = (priority) => {
  return priority == 'high'
  ? <RedFlag />
  : priority == 'medium'
  ? <OrangeFlag />
  : <YellowFlag />
}

export const taskHeight = 16.5;

const GreenTimeDot = styled(BiAlarm)`
  color: green;
  `;

const YellowTimeDot = styled(BiAlarm)`
  color: gold;
  `;

const OrangeTimeDot = styled(BiAlarm)`
  color: orange;
  `;

const RedTimeDot = styled(BiAlarmExclamation)`
  color: red;
  `;

export const renderTimeDots = (startDate, status) => {
  
  let dayDiff = 0
  const now = moment()
  if (startDate && status != 'submitted') {
    const start = moment(startDate)
    dayDiff = now.diff(start, 'days') 
  }

  let dots = [];
  if (dayDiff <= 7) {
    _.times(1, (i) => {
      dots.push(<GreenTimeDot key={i}></GreenTimeDot>)
    })
  } else if (dayDiff > 7 && dayDiff <= 14) {
    _.times(2, (i) => {
      dots.push(<GreenTimeDot key={i}></GreenTimeDot>)
    })
  } else if (dayDiff > 14 && dayDiff <= 21) {
    _.times(3, (i) => {
      dots.push(<YellowTimeDot key={i}></YellowTimeDot>)
    })
  } else if (dayDiff > 21 && dayDiff <= 28) {
    _.times(4, (i) => {
      dots.push(<OrangeTimeDot key={i}></OrangeTimeDot>)
    })
  } else if (dayDiff > 28) {
    _.times(5, (i) => {
      dots.push(<RedTimeDot key={i}></RedTimeDot>)
    })
  }
  
  return (
    <>
    {dots}
    </>
  )
}